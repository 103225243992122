<template>
  <PageWrapper :tabs="tabs" :tabActive.sync="tabActive" class="activity_wrapper" @tabClick="handleTabChange">
    <PagePanel v-if="activityData.length > 0">
      <p class="title">{{ $t('activity.activityContent') }}</p>
      <StatusAlert type="warning" show-icon :closable="false">
        <div class="alert_description">
          Before making deposit,please ensure register the bonus via this link firstly
        </div>
      </StatusAlert>
      <keep-alive>
        <component :is="currentComponent" :data="activityData" />
      </keep-alive>
    </PagePanel>
    <Empty v-else class="empty_box" :image-size="200" :description="$t('activity.noActivity')">
    </Empty>
  </PageWrapper>
</template> 

<script>
import StatusAlert from '@/components/customUI/StatusAlert.vue'
import AllActivity from './components/AllActivity.vue'
import MyActivity from './components/MyActivity.vue'
// import { fetchGetActivity } from '@/resource'

export default {
  name: 'activity',
  components: {
    AllActivity,
    MyActivity,
    StatusAlert
  },
  data() {
    return {
      tabs: [
        { name: 'tab1', label: this.$t('activity.myActivity') },
        { name: 'tab2', label: this.$t('activity.allActivity') },
      ],
      tabActive: 'tab1',
      activityData: []
    };
  },
  computed: {
    currentComponent() {
      return this.tabActive === 'tab1' ? AllActivity : MyActivity;
    }
  },
  created() {
    this.getActivityData(this.tabActive)
  },
  methods: {
    handleTabChange() {
      this.getActivityData(this.tabActive)
    },
    getActivityData(tabActive) {
      // apiHistoryList({
      //   type: tabActive,
      // })
      //   .then(resp => {

      //   })
      //   .catch(err => { })
      this.activityData = [
        {
          "activityId": "24384449a2c54239ac42c59e63b984a4",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/1685602378212906e83d2-7d11-4e24-a993-b350ac4c6609.png?Expires=2000962379&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=DNswOMo3E0CEiaTNAA3HJZhMM1o%3D",
          "detailUrl": "https://marketing-web-test1.cloudtest1.club/login#/activity/create",
          "endTime": "2023-06-30 00:00:00",
          "id": "24384449a2c54239ac42c59e63b984a4",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/168560238054901b07253-44aa-4c6c-8523-8372f7f661d2.png?Expires=2000962380&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=m6emh%2FA7bJQFSG73AUr1QV3tS5E%3D",
          "startTime": "2023-06-01 00:00:00",
          "status": 2,
          "title": "wilson-test-01",
          "url": "https://marketing-web-test1.cloudtest1.club/login#/activity/create"
        },
        {
          "activityId": "de1cd7fd53a24b2aa5fe43fba5790bf0",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16856005630556cd2d187-08d8-4fac-a849-ce9a228113c6.png?Expires=2000960563&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=%2BK8UsATHZJ%2Bqgl6BhXDqqkfr3XI%3D",
          "detailUrl": "https://marketing-web-test1.cloudtest1.club/login#/activity/create",
          "endTime": "2023-06-30 00:00:00",
          "id": "de1cd7fd53a24b2aa5fe43fba5790bf0",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/168560056544307883169-3adc-4b89-8644-fa56db630d09.png?Expires=2000960565&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=JUXvF3GDDTqv%2Fs12%2BQHTdL3Whbc%3D",
          "startTime": "2023-06-01 00:00:00",
          "status": 2,
          "title": "wilson-demo-01",
          "url": "https://marketing-web-test1.cloudtest1.club/login#/activity/create"
        },
        {
          "activityId": "5a234c08c4c440e9ace4ae2821ccc041",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16768740476462372cc7c-5cd1-4c5f-91c1-adb05b62cf96.jpg?Expires=1992234047&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=2uS8RPnBheJzDCw36aflF3c6Wzc%3D",
          "detailUrl": "1",
          "endTime": "2024-02-23 00:00:00",
          "id": "5a234c08c4c440e9ace4ae2821ccc041",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/167687405333810a18f59-a658-4ab5-963f-db03c5c88db5.bmp?Expires=1992234053&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=K4UEdZ%2BEQYVaSmauX4aOe5XyEIQ%3D",
          "startTime": "2023-02-20 00:00:00",
          "status": 2,
          "title": "普通赠金_交易有效期7天",
          "url": "1"
        },
        {
          "activityId": "85c68e95f98847b994d9a452eedbfecc",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/1654680992474ad0eb4c7-7288-4d01-8300-4fe21ece2b97.png?Expires=1970040994&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=dB4g2Jd1nc%2Bdn7UuAFuNtjAH9nY%3D",
          "detailUrl": "1",
          "endTime": "2023-07-09 00:00:00",
          "id": "85c68e95f98847b994d9a452eedbfecc",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16546810482576cebb083-6f77-47ac-8c04-a76ffa6a9419.png?Expires=1970041049&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=ptcT9lDM4tXRjpIuJ5YGNDLpf5c%3D",
          "startTime": "2022-06-01 00:00:00",
          "status": 2,
          "title": "开户活动_入金/出金转账",
          "url": "1"
        },
        {
          "activityId": "0502a893fdd64c7a9e8f97c769ed3fd9",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/166926032282456bd4e83-5838-455f-95bf-30f77675261c.png?Expires=1984620325&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=Aa%2Fvz8SPbrT8UNC0NNKABA70nEI%3D",
          "detailUrl": "1",
          "endTime": "2023-11-09 00:00:00",
          "id": "0502a893fdd64c7a9e8f97c769ed3fd9",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/1669260329405eca8a01a-258f-4592-89df-47e2a89dd397.png?Expires=1984620330&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=3hXYCBMBl7fSxF9s8iNiOfV%2FgS0%3D",
          "startTime": "2022-11-24 00:00:00",
          "status": 2,
          "title": "demo活动_fsa",
          "url": "1"
        },
        {
          "activityId": "c3562e07ccce409f8c6821048f6a56ba",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16479400761325651ba24-930b-4825-ab01-b4dcecde20c5.bmp?Expires=1963300076&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=go1XVnZ%2BBj7R%2BdOvWVU7Nn3rhPg%3D",
          "detailUrl": "1",
          "endTime": "2023-09-13 00:00:00",
          "id": "c3562e07ccce409f8c6821048f6a56ba",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/164794008023259cb1f33-d20c-47fa-bb76-d047272ce2fd.png?Expires=1963300080&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=nhOUsRkHEoSk9Tl%2FiHiav7QLjmI%3D",
          "startTime": "2020-08-04 14:00:00",
          "status": 2,
          "title": "持续赠金活动_不可抗亏损60%",
          "url": "1"
        },
        {
          "activityId": "f34a0cf4c925493091d52f54e86ac02c",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16479400761325651ba24-930b-4825-ab01-b4dcecde20c5.bmp?Expires=1963300076&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=go1XVnZ%2BBj7R%2BdOvWVU7Nn3rhPg%3D",
          "detailUrl": "1",
          "endTime": "2023-06-30 00:00:00",
          "id": "f34a0cf4c925493091d52f54e86ac02c",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/164794008023259cb1f33-d20c-47fa-bb76-d047272ce2fd.png?Expires=1963300080&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=nhOUsRkHEoSk9Tl%2FiHiav7QLjmI%3D",
          "startTime": "2023-05-24 14:00:00",
          "status": 2,
          "title": "盈利出金_5491616",
          "url": "1"
        },
        {
          "activityId": "8c92e9477f45446ba50a79d80635c666",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16479400761325651ba24-930b-4825-ab01-b4dcecde20c5.bmp?Expires=1963300076&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=go1XVnZ%2BBj7R%2BdOvWVU7Nn3rhPg%3D",
          "detailUrl": "1",
          "endTime": "2023-06-30 00:00:00",
          "id": "8c92e9477f45446ba50a79d80635c666",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/164794008023259cb1f33-d20c-47fa-bb76-d047272ce2fd.png?Expires=1963300080&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=nhOUsRkHEoSk9Tl%2FiHiav7QLjmI%3D",
          "startTime": "2023-05-24 14:00:00",
          "status": 2,
          "title": "盈利出金_1302986",
          "url": "1"
        },
        {
          "activityId": "d032907051c242c7b11a65638c234f00",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16479400761325651ba24-930b-4825-ab01-b4dcecde20c5.bmp?Expires=1963300076&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=go1XVnZ%2BBj7R%2BdOvWVU7Nn3rhPg%3D",
          "detailUrl": "1",
          "endTime": "2023-06-30 00:00:00",
          "id": "d032907051c242c7b11a65638c234f00",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/164794008023259cb1f33-d20c-47fa-bb76-d047272ce2fd.png?Expires=1963300080&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=nhOUsRkHEoSk9Tl%2FiHiav7QLjmI%3D",
          "startTime": "2023-05-24 14:00:00",
          "status": 2,
          "title": "盈利出金_2328075",
          "url": "1"
        },
        {
          "activityId": "25c1fc4c05cc4d798001dfb98d226bc0",
          "apply": true,
          "applyType": 2,
          "banner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/16479400761325651ba24-930b-4825-ab01-b4dcecde20c5.bmp?Expires=1963300076&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=go1XVnZ%2BBj7R%2BdOvWVU7Nn3rhPg%3D",
          "detailUrl": "1",
          "endTime": "2023-06-30 00:00:00",
          "id": "25c1fc4c05cc4d798001dfb98d226bc0",
          "smallBanner": "http://filebucket-marketing.oss-cn-hongkong.aliyuncs.com/static/images/164794008023259cb1f33-d20c-47fa-bb76-d047272ce2fd.png?Expires=1963300080&OSSAccessKeyId=LTAI4FcTqG43BTMEG3uEgxaL&Signature=nhOUsRkHEoSk9Tl%2FiHiav7QLjmI%3D",
          "startTime": "2023-05-24 14:00:00",
          "status": 2,
          "title": "盈利出金_7108709",
          "url": "1"
        }
      ]
    }
  },
};
</script>

<style lang="scss">
.activity_wrapper {
  .title {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .el-alert--warning {
    .el-alert__description {
      margin: 0;
    }
  }
}
</style>
