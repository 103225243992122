<template>
  <div class="all_activity">
    <el-row :gutter="16" wrap class="activity_list">
      <el-col :span="8" :xs="24" :sm="12" :md="12" :lg="8" :xl="6" :xxl="6" v-for="item in data" :key="item.id">
        <ActivityItem :data="item" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ActivityItem from './ActivityItem.vue'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
  name: 'allActivity',
  components: { ActivityItem },
}
</script>

<style lang="scss">
</style>
