<template>
  <SDialog :visible.sync="visible" :title="$t('activity.selectAccount')" :showFooterConfirm="false" @cancel="closeDialog">
    <el-form label-position="top" :model="form" :rules="rules" ref="form" v-if="isShow">
      <el-form-item :label="$t('activity.selectAccount')" prop="applyAccountList">
        <el-select v-model="form.applyAccountList" style="width: 100%;" multiple>
          <el-option-group v-for="group in accountOptions" :key="group.label" :label="group.label">
            <el-option v-for="item in group.options" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item prop="checked">
        <el-checkbox v-model="form.checked">
          <div class="agreement">
            <p>{{ $t('activity.activityReminder1', { capitalName: $config.info.capitalName }) }}</p>
            <p>{{ $t('activity.activityReminder2') }}</p>
            <p>{{ $t('activity.activityReminder3') }}</p>
            <p>{{ $t('activity.activityReminder4') }}</p>
            <p>{{ $t('activity.activityReminder5') }}</p>
            <p>{{ $t('activity.activityReminder6', { capitalName: $config.info.capitalName }) }}</p>
          </div>
        </el-checkbox>
      </el-form-item>
    </el-form>
    <template slot="appendFooter" v-if="isShow">
      <el-button type="primary" :disabled="isDisabled" @click="handleConfirm">{{ confirmText }}</el-button>
    </template>
    <Empty v-else class="empty_box" :image-size="160" :description="$t('activity.noAccountLabel')">
    </Empty>
  </SDialog>
</template>
<script>

export default {
  data() {
    const checkValidate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('activity.checkConfirm')))
      }
      return callback()
    }
    return {
      visible: false,
      tradeAccountUsedList: [],
      tradeAccountList: [],
      activityId: '',
      countdown: 2,
      timer: null,
      form: {
        applyAccountList: [],
        checked: false
      },
      rules: {
        applyAccountList: [{ required: true, message: this.$t(`common.field.select`), trigger: 'change' }],
        checked: [{ required: true, validator: checkValidate, trigger: 'change' }]
      }
    }
  },
  computed: {
    isShow() {
      return (this.tradeAccountUsedList && this.tradeAccountUsedList.length > 0) || (this.tradeAccountList && this.tradeAccountList.length > 0)
    },
    confirmText() {
      if (this.countdown === 0) {
        return this.$t('activity.readAndAgree')
      } else {
        return `${this.countdown} s`
      }
    },
    isDisabled() {
      return this.countdown !== 0
    },
    accountOptions() {
      let result = []
      if (this.tradeAccountList.length > 0) {
        result.push({
          label: 'Available',
          options: this.tradeAccountList.map(item => ({
            value: item,
            label: item
          })),
        })
      }
      if (this.tradeAccountUsedList.length > 0) {
        result.push({
          label: 'Attended',
          options: this.tradeAccountUsedList.map(item => ({
            value: item,
            label: item
          })),
        })
      }
      return result
    },

  },
  methods: {
    openDialog(visible, data) {
      this.visible = visible
      this.tradeAccountUsedList = data.listObj.oldAccounts
      this.tradeAccountList = data.listObj.newAccounts
      this.activityId = data.activityId
      this.handleInterval()
    },
    closeDialog() {
      this.visible = false
      clearInterval(this.timeInterval)
      this.$refs['form'].resetFields()
    },
    handleInterval() {
      this.timer = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(this.timer)
        } else {
          this.countdown--
        }
      }, 1000)
    },

    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const selectList = this.form.applyAccountList
          const UsedList = this.tradeAccountUsedList
          // If the selected trading account is participating in other bonus activities, a second confirmation pop-up window is required
          if (selectList.some((item) => UsedList.includes(item))) {
            this.$confirm(this.$t('activity.activityCancelReminder1'), this.$t('common.field.tips'), {
              closeOnPressEscape: false,
              closeOnClickModal: false,
              type: 'warning',
            }).then(() => {
              this.$confirm(this.$t('activity.activityCancelReminder2'), this.$t('common.field.tips'), {
                closeOnPressEscape: false,
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                this.fetchActivity()
              })
            })
          } else {
            this.fetchActivity()
          }
        }
      });
    },
    fetchActivity() {
      // fetchActivityApplyV2({
      //   activityAccounts: form.value.applyAccountList,
      //   activityId: activityId.value,
      // }).then(() => {
      //   this.$message({
      //     message: this.$t('activity.applySuccess'),
      //     type: 'success',
      //   })
      //   this.visible = false
      // })
      this.$message({
        message: this.$t('activity.applySuccess'),
        type: 'success',
      })
      this.closeDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-tag.el-tag--info .el-tag__close {
  background-color: $text-placeholder;
  color: $white;
}

/deep/ .el-checkbox {
  display: flex;
}

/deep/ .el-checkbox__input {
  margin-top: 2px;
}

.agreement {
  @include rtl-sass-prop(margin-left, margin-right, 4px);

  p {
    font-size: 14px;
    color: $text-secondary;
    line-height: 21px;
  }
}
</style>
